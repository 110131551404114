import { inject } from '@angular/core';
import { TenantI, PricingPlanI } from '@bodyanalytics/data-models-ui';
import { tapResponse } from '@ngrx/operators';
import { signalStore, withState, withMethods, patchState, withHooks } from '@ngrx/signals';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { HomeService } from './home.service';

export const HomeStore = signalStore(
  withState({ loading: false }),
  withEntities<TenantI>(),
  // withEntities({ collection: 'plans', entity: type<PricingPlanI>() }),
  withEntities({ collection: 'plans', entity: <PricingPlanI>{} }), // Fix the type specification here

  withMethods((s, service = inject(HomeService)) => ({
    // load: rxMethod<void>(
    //   pipe(
    //     switchMap(() => (patchState(s, { loading: true }), service.load())),
    //     tapResponse({
    //       next: entities => patchState(s, setAllEntities(entities)),
    //       error: () => patchState(s, { loading: false }),
    //       finalize: () => patchState(s, { loading: false }),
    //     }),
    //   ),
    // ),
    // loadPlans: rxMethod<void>(
    //   pipe(
    //     switchMap(() =>
    //       service.loadPlans().pipe(
    //         tapResponse({
    //           next: (entities: PricingPlanI[]) => patchState(s, setAllEntities(entities, { collection: 'plans' })), // Specify idKey
    //           error: error => {
    //             console.error(error);
    //             patchState(s, { loading: false });
    //           },
    //         }),
    //       ),
    //     ),
    //   ),
    // ),
  })),
  withHooks(s => ({
    onInit: () =>
      // s.load()
    {}
  })),
);
