<section id="home" class="intro-section home-bg">
  <div class="container">
    <div class="row align-items-center text-white">
      <!-- START THE CONTENT FOR THE INTRO  -->
      <div class="col-md-6 intros text-start">
        <h1 class="display-2">
          <span class="display-3--intro">Hydrostatic Body Fat Testing</span>
          <span class="display-2--description lh-base"> Fast, Accurate, Body Fat Testing. </span>
        </h1>
      </div>
      <!-- START THE CONTENT FOR VIDEO -->
      <div class="col-md-6"></div>
    </div>
  </div>
</section>
<section id="home" *ngIf="isAdminPortal()">
  <div class="container">
    <div class="row align-items-center text-dark py-3 bg-white">
      <div class="row align-items-center">
        <div class="col-md-12">
          <h1 class="text-center mb-0">Pricing Plans</h1>
          <div class="mt-5 border py-5">

            <lib-pricing-table></lib-pricing-table>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>
