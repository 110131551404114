import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { ENV } from '@bodyanalytics/app-config';
import { TenantI, TenantResponse, PricingPlanI, OperatorI } from '@bodyanalytics/data-models-ui';
import { ApiLocalRoutesEnum, SharedLogService, SharedErrorService } from '@bodyanalytics/ui-core';
import { Observable, map, catchError, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private env = inject(ENV);
  private tenantUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TENANT}`;
  private subPlansUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TENANT}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
    @Inject(ENV) private appConfig: any,
  ) {}

  load(): Observable<TenantI[]> {
    const url = `${this.appConfig.api}/${this.tenantUrl}`;
    console.log(url);
    return this.http.get<TenantI[]>(url).pipe(
      tap(_ => console.log('fetched tenants')),
      catchError(this.sharedErrorService.handleError<TenantI[]>('getTenants', [])),
    );
  }

  private handleError(error: HttpErrorResponse): Observable<TenantResponse> {
    let errorMessage = 'An error occurred';

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = error.error.message;
    } else {
      // Server-side error
      errorMessage = error.error?.message || `Error Code: ${error.status}`;
    }

    return throwError(() => ({
      success: false,
      error: errorMessage,
    }));
  }
}
