import { Component, Inject, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { filter, switchMap, tap } from 'rxjs';
import { HomeService, HomeStore } from '@bodyanalytics/front/shared/home/domain';
import { PricingTableComponent } from '@bodyanalytics/front/shared/home/ui';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ENV } from '@bodyanalytics/app-config';

@Component({
  selector: 'lib-front-shared-home-feature',
  standalone: true,
  imports: [CommonModule, PricingTableComponent],
  templateUrl: './front-shared-home-feature.component.html',
  styleUrl: './front-shared-home-feature.component.scss',
})
export class FrontSharedHomeFeatureComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  protected store = inject(HomeStore);
  id = input<string>();
  // protected plans = this.store.plansEntities;
  // protected data = toSignal(
  //   toObservable(this.id).pipe(
  //     filter(Boolean),
  //     switchMap(id => this.homeService.getTenant(id)),
  //   ),
  // );

  constructor(
    // private location: Location,
    private homeService: HomeService,
    @Inject(ENV) private appConfig: any,
    public auth: AuthService,
    private router: Router,
  ) {
    super();

    if (this.isAdminPortal()) {
      this.auth.isAuthenticated$.pipe(
        tap((loggedIn: any) => {
          console.log('is user logged in? ', loggedIn);
          if (loggedIn) {
            console.log('send them here:');
            const url = `${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`;
            this.router.navigateByUrl(url);
          }
        }),
      );
    }
  }
  public isAdminPortal(): boolean {
    if (this.appConfig.portalName === 'admin') {
      return true;
    }
    return false;
  }
}
